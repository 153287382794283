/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss'

// ================================
// START YOUR APP HERE
// ================================

const THREE = require('three')
const OrbitControls = require('three-orbit-controls')(THREE)
let renderer = new THREE.WebGLRenderer()
renderer.setPixelRatio(window.devicePixelRatio || 1)
renderer.setSize(window.innerWidth, window.innerHeight)
document.body.appendChild(renderer.domElement)

let scene = new THREE.Scene()
// let camera = new THREE.OrthographicCamera(window.innerWidth / - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / -2, 1, 10000)
let camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000)
camera.position.z = 5
camera.rotation.y = toRads(10)
camera.rotation.x = toRads(5)
// let controls = new THREE.OrbitControls(camera)
const nbSpheres = {
    x: 60,
    z: 40
}

let spheresArray = []
const sphereGeometry = new THREE.SphereGeometry(1, 8, 8)
const distanceBetweenSpheres = {
    x: 10,
    z: 20
}
const sphereMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    wireframe: true
})
let clock = new THREE.Clock()

for (let i = 0; i < nbSpheres.z; i++) {
    spheresArray[i] = []
    for (let j = 0; j < nbSpheres.x; j++) {
        spheresArray[i][j] = new THREE.Mesh(sphereGeometry, sphereMaterial)
        spheresArray[i][j].position.x = j * distanceBetweenSpheres.x - 300
        spheresArray[i][j].position.z = -1 * i * distanceBetweenSpheres.z
        scene.add(spheresArray[i][j])
    }
}

camera.position.set(0, 45, 120)

animate()

function animate () {
    let clockTime = clock.getElapsedTime()
    requestAnimationFrame(animate)

    for (let i = 0; i < nbSpheres.z; i++) {
        // spheresArray[i].position.y = Math.sin(clockTime * 3) * 12 * Math.sin(i)
        /*spheresArray[i].rotation.z = clockTime
        spheresArray[i].rotation.x = clockTime*/
        for (let j = 0; j < nbSpheres.x; j++) {
            spheresArray[i][j].position.y = Math.sin(clockTime) * 1.5 * (Math.cos(i) * 1.5)
            // spheresArray[i][j].position.z -= 0.15
            // spheresArray[i][j].scale.x = spheresArray[i][j].scale.y = spheresArray[i][j].scale.z = (Math.sin(clockTime))
        }

    }

    console.log(Math.round(clockTime))
    camera.position.z -= 0.02
    camera.position.y -= 0.001
    camera.rotation.z -= 0.0001
    camera.rotation.y -= 0.0001



    // camera.rotation.y = Math.sin(toRads(clockTime))
    // camera.rotation.z = Math.sin(toRads(clockTime))

    // camera.rotation.y = Math.sin(clockTime) / 20
    // camera.position.z -= 0.01

    renderer.render(scene, camera)
}


function toRads(degrees) {
    return degrees * Math.PI / 180
}
